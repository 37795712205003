const menuButton = $("#menuButton");
const mainNav = $(".primary-nav");

function launchMobileNav() {
  mainNav.css("display", "flex");
  mainNav.hide();
  mainNav.fadeIn("fast");
  menuButton.attr("aria-expanded", "true");
  menuButton.text("Close");
}

function destroyMobileNav() {
  mainNav.fadeOut("fast");
  menuButton.removeAttr("aria-expanded");
  menuButton.text("Menu");
}

menuButton.on("click", function () {
  if (menuButton.attr("aria-expanded")) {
    destroyMobileNav();
  } else {
    launchMobileNav();
  }
});
