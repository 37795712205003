const billingAddressRequiredFields = $("fieldset#billingAddress *[required]");

billingAddressRequiredFields.each(function () {
  $(this).prop("required", false);
});

$("#billingAddressSameAsShipping").change(function () {
  const billingAddressFieldset = $("fieldset#billingAddress");
  if ($(this).is(":checked")) {
    billingAddressFieldset.addClass("fieldset--hidden");
    billingAddressRequiredFields.each(function () {
      $(this).prop("required", false);
    });
  } else {
    billingAddressFieldset.removeClass("fieldset--hidden");
    billingAddressRequiredFields.each(function () {
      $(this).prop("required", true);
    });
  }
});
