const splideSpeed = 1000;

if (document.getElementsByClassName("hero--images__splide").length) {
  var heroImagesSplide = new Splide(".splide.hero--images__splide", {
    type: "fade",
    rewind: true,
    drag: false,
    autoplay: true,
    speed: splideSpeed,
  }).mount();

  heroImagesSplide.on("move", function() {
    // TODO - animate progress
  })
}

if (document.getElementsByClassName("hero--carousel__splide").length) {
  var heroCarouselSplide = new Splide(".splide.hero--carousel__splide", {
    type: "fade",
    rewind: true,
    autoplay: true,
    speed: splideSpeed,
    arrows: false,
    pagination: false
  });

  var heroCarouselControlsSplide = new Splide(".splide.hero--carousel-controls__splide", {
    arrows: false,
    pagination: false,
    perPage: 3,
    gap: 10,
    rewind: true,
    autoWidth: true,
    isNavigation: true
  });

  heroCarouselSplide.sync(heroCarouselControlsSplide);
  heroCarouselSplide.mount();
  heroCarouselControlsSplide.mount();
}

if (document.getElementsByClassName("image-gallery--carousel").length) {
  var imageCarouselSplide = new Splide(".splide.image-gallery--carousel", {
    type: 'loop',
    gap: 16
  });

  var imageCarouselControlsSplide = new Splide(".splide.image-gallery--carousel-controls",  {
    perPage: 6,
    gap: 16,
    rewind: true,
    arrows: false,
    pagination: false,
    isNavigation: true,
    focus: 'center'
  });

  imageCarouselSplide.sync(imageCarouselControlsSplide);
  imageCarouselSplide.mount();
  imageCarouselControlsSplide.mount();
}